<template>
  <b-card>
    <div>
      <b-overlay
        :show="showProfileUpdateOverlay"
        rounded="sm"
        variant="dark"
      >
        <div>
          <!-- Media -->
          <b-media class="mb-2">
            <template #aside>
              <b-avatar
                ref="previewEl"
                :src="userData.avatar"
                :text="avatarText(userData.name)"
                size="90px"
                rounded
              />
            </template>
            <h4 class="mb-1">
              {{ userData.name }}
            </h4>
            <div class="d-flex flex-wrap">
              <b-button
                variant="primary"
                @click="$refs.refInputEl.click()"
              >
                <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="inputImageRenderer"
                  @change="selectFile"
                >
                <span class="d-none d-sm-inline">Update</span>
                <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
              <b-button
                variant="outline-secondary"
                class="ml-1"
              >
                <span class="d-none d-sm-inline">Remove</span>
                <feather-icon
                  icon="TrashIcon"
                  class="d-inline d-sm-none"
                />
              </b-button>
            </div>
          </b-media>

          <!-- form -->
          <b-form
            class="mt-2"
            @submit="submitForm"
          >
            <b-row>
              <b-col sm="6">
                <b-form-group
                  label="Name"
                  label-for="account-name"
                >
                  <b-form-input
                    v-model="userData.name"
                    name="name"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Business Name"
                  label-for="business-name"
                >
                  <b-form-input
                    v-model="userData.business_name"
                    name="business_name"
                    placeholder="Business Name"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="E-mail"
                  label-for="account-e-mail"
                >
                  <b-form-input
                    v-model="userData.email"
                    name="email"
                    placeholder="Email"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6">
                <b-form-group
                  label="Phone"
                  label-for="account-company"
                >
                  <b-form-input
                    v-model="userData.phone"
                    name="company"
                    placeholder="Company name"
                  />
                </b-form-group>
              </b-col>

              <!-- alert -->
              <b-col
                cols="12"
                class="mt-75"
              >
                <!-- <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert> -->
              </b-col>
              <!--/ alert -->

              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  type="submit"
                >
                  Save changes
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  type="reset"
                  class="mt-2"
                  @click.prevent="resetForm"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-overlay>
    </div>
  </b-card>
</template>

<script>
import {
  BAvatar,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BMedia,
  BOverlay,
} from 'bootstrap-vue'

import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import { apiUrl } from '@/constants/config'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAvatar,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BMedia,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      // profile: JSON.parse(JSON.stringify(this.userData)),
      profileFile: null,
      showProfileUpdateOverlay: false,
    }
  },
  methods: {
    selectFile(event) {
      // `files` is always an array because the file input may be in multiple mode
      [this.profileFile] = event.target.files
    },
    resetForm() {
      this.profile = JSON.parse(JSON.stringify(this.userData))
    },
    submitForm(event) {
      this.showProfileUpdateOverlay = true
      event.preventDefault()
      const data = new FormData()
      data.append('avatar', this.profileFile)
      data.append('name', this.userData.name)
      data.append('phone', this.userData.phone)
      data.append('business_name', this.userData.business_name)
      data.append('email', this.userData.email)
      data.append('_method', 'PUT')
      axios({
        url: apiUrl.slice(0, -1),
        data,
        method: 'POST', // "PUT",
        headers: {
          'content-type': 'application/json',
          Authorization: `Bearer ${store.state.user.currentUser.token}`,
        },
      })
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Success: ${response.data.message}`,
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        //   this.onResetChangePassword(event)
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
                `Error: ${
                  error.response.status === 422
                    ? error.response.data.message
                    : error.message}`,
              icon: 'EditIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.showProfileUpdateOverlay = false
        })
    },
  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        // eslint-disable-next-line no-param-reassign
        props.userData.avatar = base64
      },
    )

    return {
      avatarText,

      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
